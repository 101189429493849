<template>
    <div class="user-container">
        <CRow>
            <CCol col="12">
                <CCard class="p-0">
                    <CCardHeader>
                        <div class="d-flex">
                            <div class="flex-grow-1">
                                <h5>{{titleListReport}}</h5>
                            </div>
                            <div class="flex-grow-0 ml-10">
                                <CButton v-if="isAdmin == false && isArchiveTicketProp == false" class="btn-sm" color="info" @click="showAddPopUp()">
                                    {{CreateReportButtonText}}
                                </CButton>
                                <CButton v-if="isAdmin && isSystemTicketProp == true && isArchiveTicketProp == false" class="btn-sm" color="info" @click="showAddPopUp()">
                                    {{CreateReportButtonText}}
                                </CButton>
                            </div>
                        </div>
                    </CCardHeader>
                    <CCardBody>
                        <v-data-table
                            :loading="loading"
                            loading-text="Memuat Data..."
                            :headers="headers"
                            :options.sync="optionsTable"
                            :server-items-length="totalData"
                            :multi-sort="false"
                            :items="ticketList"
                            class="elevation-1">
                                <template v-slot:item.actions="{ item }">
                                    <v-icon v-c-tooltip="'Buka detil tiket'" small class="mr-2 text-info" @click="showDetail(item)">
                                        mdi-file
                                    </v-icon>
                                    <v-icon v-c-tooltip="'Percakapan'" small class="mr-2 text-info" @click="showTicketCommentPopUp(item)">
                                        mdi-message
                                    </v-icon>
                                    <v-icon v-c-tooltip="'Unggah lampiran pendukung tiket'" small class="mr-2 text-info" @click="showUploadExtraFilePopUp(item)">
                                        mdi-upload
                                    </v-icon>
                                    <v-icon v-c-tooltip="'Unduh lampiran pendukung tiket'" v-if="item.file_attachment != null" small class="mr-2 text-info" @click="downloadAttachment(item, false)">
                                        mdi-download
                                    </v-icon>
                                    <v-icon v-c-tooltip="'Unduh lampiran bukti penyelesaian'" v-if="item.evidence_file != null" small class="mr-2 text-success" @click="downloadAttachment(item, true)">
                                        mdi-download
                                    </v-icon>
                                    <v-icon v-c-tooltip="'Beri ulasan tiket'" v-if="item.status.toLowerCase() == 'done' || item.status.toLowerCase() == 'selesai'" small class="mr-2 text-success" @click="showRatingPopUp(item)">
                                        mdi-thumb-up
                                    </v-icon>

                                </template>
                                <template v-slot:no-data>
                                    <span>Data Tidak Ditemukan</span>
                                </template>
                        </v-data-table>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>

        <CModal :show.sync="showDetailDataPopUp">
            <template #header>
                <h5 class="modal-title">Deskripsi {{ ticket_no }}</h5>
            </template>
            <div class="p-1">
                {{ description }}
            </div>
            <hr>
            <div class="p-1">
                <h6><b>Dilaporkan Oleh</b></h6>
                {{ createdBy }}
            </div>
            <hr>
            <div class="p-1">
                <h6><b>Dilaporkan Pada</b></h6>
                {{ createdAt }}
            </div>
            <hr>
            <div class="p-1">
                <h6><b>Penanggung Jawab</b></h6>
                {{ handledBy }}
            </div>
            <hr>
            <div class="p-1">
                <h6><b>Status Penyelesaian</b></h6>
                {{ status }}
            </div>
            <hr>
            <div class="p-1">
                <h6><b>Proses Terakhir pada</b></h6>
                {{ changedAt }}
            </div>
            <div class="p-1" v-if="0< rating">
                <h6><b>Rating:</b></h6>
                <v-icon v-for="iconR in ratingStar" v-bind:key="iconR" small class="mr-2 text-warning">mdi-star</v-icon>
                <v-icon v-for="iconO in ratingStarOff" v-bind:key="iconO" small class="mr-2">mdi-star</v-icon>
            </div>
            <div class="p-1" v-if="0< rating">
                <h6><b>Catatan Rating:</b></h6>
                {{ratingNote}}
            </div>

            
            <div class="p-1" id="refTicketId">
                <hr>
                *catatan: Tiket ini dibuat oleh sistem karena tiket referensi ({{refTicket}}) sudah lewat masa berlakunya.
            </div>
            <template #footer>
                <CButton @click="closePopupModal()" class="btn-sm">Tutup</CButton>
            </template>
        </CModal>

        

        <CModal :show.sync="showUploadFilePopUp">
            <template #header>
                <h5 class="modal-title">Unggah File Tambahan</h5>
            </template>
            <div class="p-1">
                <CInputFile
                    ref="file"
                    :placeholder="placeHolderFileInput"
                    :multiple="true"
                    custom
                    @change="validateAttachmentForm"/>
                <div class="row mt-2">
                    <div v-for="item in fileUploadedInfoList" v-bind:key="item">

                        <div class="col-auto" 
                            v-if="item.isChoosed" >
                            <CButton
                            color="info"
                            variant="outline"
                            @click="unChoosedItem(item)" >
                                <font-awesome-icon icon="image" class="mr-1" v-if="item.file_type == 'image'"/>
                                <font-awesome-icon icon="video" class="mr-1" v-if="item.file_type == 'video'"/> 
                                <font-awesome-icon icon="file" class="mr-1" v-if="item.file_type == 'others'"/> 
                                {{ item.name }}
                                &nbsp;<CBadge><font-awesome-icon icon="times"/></CBadge>
                            </CButton>
                        </div>
                    </div>
                </div>
            </div>
            
            <template #footer>
                <CButton @click="closePopupModal()" class="btn-sm">Tutup</CButton>
                <CButton @click="saveUploadFileData()" color="success" class="btn-sm" :disabled="fileUploadedInfoList.length == 0">
                    <font-awesome-icon v-if="saveLoader" icon="spinner" spin/> {{ saveButtonText }}
                </CButton>
            </template>
        </CModal>


        
        <CModal :show.sync="showDownloadPopUp">
            <template #header>
                <h5 class="modal-title">Unduh File {{downloadType}}</h5>
            </template>
            <div class="p-1"> 
                <div class="row">
                    <div v-for="item in fileReadyToDownloadList" v-bind:key="item">

                        <div class="col-auto">
                            <CButton
                            color="info"
                            variant="outline"
                            @click="downloadFile(item)" >
                                <font-awesome-icon icon="image" class="mr-1" v-if="item.file_type == 'image'"/>
                                <font-awesome-icon icon="video" class="mr-1" v-if="item.file_type == 'video'"/> 
                                <font-awesome-icon icon="file" class="mr-1" v-if="item.file_type == 'others'"/> 
                                {{ item.filename }}
                                &nbsp;<CBadge><font-awesome-icon icon="download"/></CBadge>
                            </CButton>
                        </div>
                    </div>
                </div>
            </div>
            
            <template #footer>
                <CButton @click="closePopupModal()" class="btn-sm">Batal</CButton>
            </template>
        </CModal>
    
        <!-- Popup Modal & Input Form -->
        <CModal :show.sync="showCreatePopUpModal" :closeOnBackdrop="false">
            <template #header>
                <h5 class="modal-title">{{createdTicketTitle}}</h5>
            </template>
            <div class="p-1">
                <CInput
                    v-model="ticketData.title"
                    label="Judul Laporan"
                    placeholder="Judul Laporan"
                    :is-valid="ticketTitleIsValid"
                    @input="validateTitle()" />
                    
                <CTextarea
                    v-model="ticketData.description"
                    label="Deskripsi Laporan"
                    rows="5"
                    :is-valid="ticketDescriptionIsValid"
                    @input="validateDescription()" />
                    
                <div class="mb-20">
                    <multiselect
                        v-model="selectedTicketCategory"
                        :allow-empty="true"
                        :close-on-select="true"
                        :maxHeight="190"
                        :multiple="false"
                        :options="ticketCategoryList"
                        deselectLabel="Tekan 'enter' untuk menghapus"
                        openDirection="bottom"
                        placeholder="Pilih Kategori Laporan"
                        selectLabel="Tekan 'enter' untuk memilih"
                        selectedLabel="Terpilih"
                        track-by="category_name"
                        label="category_name"
                        @input="selectCategoryTicket()">
                        <template slot="singleLabel" slot-scope="{ option }">{{ option.category_name }}</template>
                    </multiselect>
                </div>
                <CInputFile
                    ref="file"
                    :placeholder="placeHolderFileInput"
                    :multiple="true"
                    custom
                    @change="validateAttachmentForm"/>
                <div class="row mt-2">
                    <div v-for="item in fileUploadedInfoList" v-bind:key="item">

                        <div class="col-auto" 
                            v-if="item.isChoosed" >
                            <CButton
                            color="info"
                            variant="outline"
                            @click="unChoosedItem(item)" >
                                <font-awesome-icon icon="image" class="mr-1" v-if="item.file_type == 'image'"/>
                                <font-awesome-icon icon="video" class="mr-1" v-if="item.file_type == 'video'"/> 
                                <font-awesome-icon icon="file" class="mr-1" v-if="item.file_type == 'others'"/> 
                                {{ item.name }}
                                &nbsp;<CBadge><font-awesome-icon icon="times"/></CBadge>
                            </CButton>
                        </div>
                    </div>
                </div>

                
            </div>
            <template #footer>
                <CButton @click="closePopupModal()" class="btn-sm">Batal</CButton>
                <CButton  @click="saveData()" color="success" class="btn-sm" :disabled="saveButtonIsDisable">
                    <font-awesome-icon v-if="saveLoader" icon="spinner" spin/> {{ saveButtonText }}
                </CButton>
            </template>
        </CModal>

        <!-- Popup Modal & Rating Form -->
        <CModal :show.sync="showRatingModal" :closeOnBackdrop="false">
            <template #header>
                <h5 class="modal-title">Survey Kepuasan Pengguna {{title}}</h5>
            </template>
            <div class="p-1">
                <div class="p-0 mb-2">
                    <p class="mb-0">Rating Kepuasan:</p>
 
                    <v-icon v-for="a in setStarOn" 
                            v-bind:key="a" 
                            small 
                            class="mr-2 text-warning clickable"
                            @mouseover="hoverStar(a)"
                            @mouseleave="leaveHoverStar()"
                            @click="setStar(a)">
                                mdi-star
                    </v-icon>
                    <v-icon v-for="b in setStarOff" 
                            v-bind:key="b" 
                            small 
                            class="mr-2 clickable"
                            @mouseover="hoverStar(b+setStarOn)"
                            @mouseleave="leaveHoverStar()"
                            @click="setStar(b+setStarOn)">
                                mdi-star
                    </v-icon>
                </div>

                <CTextarea
                    v-model="ticketData.catatan_rating"
                    label="Catatan"
                    rows="5" />
            </div>
            <template #footer>
                <CButton @click="closeRatingPopUp()" class="btn-sm">Batal</CButton>
                <CButton  @click="saveRatingData()" color="success" class="btn-sm" :disabled="saveRatingButtonIsDisable">
                    <font-awesome-icon v-if="saveLoader" icon="spinner" spin/> Simpan
                </CButton>
            </template>

        </CModal>

        <!-- Popup Modal & Comment -->
        <CModal :show.sync="commentTicket.showPopUp">
            <template #header>
                <h5 class="modal-title">Percakapan Ticket {{commentTicket.ticketCode}}</h5>
            </template>
            <div v-if="commentTicket.data.length == 0" class="text-center p-3">
                <font-awesome-icon icon="info-circle" size="2x" class="text-info mb-10"/><br/>
                Tidak Ada Percakapan
            </div>
            <div v-else class="mt-1">
                <CListGroup class="list-group-flush p-0 mb-10" style="max-height:400px;overflow-y: auto;">
                    <CListGroupItem v-for="(item, index) in commentTicket.data" :key="index" class="flex-column align-items-start p-1">
                        <div class="d-flex w-100">
                            <CCol col="2">
                            <div class="media-img-wrap">
                                <img :src="avatar" alt="user" class="avatar-img rounded-circle img-fluid">
                            </div>
                            </CCol>
                            <CCol col="8">
                                <CRow><b>{{item.user.position}}</b></CRow>
                                <CRow><small>{{item.created_at}}</small></CRow>
                                <CRow>{{item.message}}</CRow>
                            </CCol>
                        </div>
                    </CListGroupItem>
                </CListGroup>
            </div>
            <div class="doc-comment-input-container">
                <div class="d-flex w-100">
                    <div class="flex-grow-1">
                        <CInput
                            v-model="commentTicket.form.message"
                            @keyup.enter="postComment"
                            class="comment-bar"
                            placeholder="Beri komentar...">
                            <template #append-content>
                                <div class="clickable" @click="postComment" :disabled="true">
                                    <font-awesome-icon v-if="commentTicket.postLoader" icon="spinner" spin/>
                                    <font-awesome-icon v-else icon="paper-plane"/>
                                </div>
                            </template>
                        </CInput>
                    </div>
                </div>
            </div>
            <template #footer>
                <CButton @click="closeCommentTicketPopUp()" class="btn-sm">Tutup</CButton>
            </template>
        </CModal>
    </div>
</template>
    
<script>
import 'vue-multiselect/dist/vue-multiselect.min.css'
import { mapState } from 'vuex'
const avatar = require('@/assets/img/avatar.svg')
export default {
    names: 'Ticket',
    props: {
        isSystemTicketProp: {
            type: Boolean,
            default: false
        },
        isArchiveTicketProp: {
            type: Boolean,
            default: false
        },
    },
    data () {
        return {
            avatar,

            ticketData: {
                ticket_id: 0,
                title: '',
                description: '',
                category_id: 0,
                file_id: [],
                rating: 0,
                catatan_rating: '',
            },

            ticketList: [],

            loading: false,
            headers: [
                { text: 'Kode Ticket', value: 'ticket_no', align: 'left', sortable: true },
                { text: 'Judul', value: 'title', align: 'left', sortable: true },
                { text: 'Kategori', value: 'category', align: 'center', sortable: true },
                { text: 'Status', value: 'status', align: 'center', sortable: true },
                { text: 'Tanggal Dibuat', value: 'created_at'},
                { text: 'Tingkat Prioritas', value: 'priority'},
                { text: 'Aksi', value: 'actions', sortable: false, align: 'left' }
            ],

            ticketTitleIsValid: false,
            ticketDescriptionIsValid: false,
            ticketSelectedCategoryIsValid: false,
            ticketAttachementIsValid: true,
            selectedTicketCategory: null,
            ticketCategoryList: [],

            saveButtonIsDisable: true,
            showCreatePopUpModal: false,
            saveLoader: false,
            totalData: 0,
            optionsTable: {},
            placeHolderFileInput: 'Pilih (Gambar, Video, dan Lainnya)',
            fileAttachmentList: [],
            fileUploadedIdList: [],

            saveButtonText: 'Simpan',
            description: '',
            title: '',
            ticket_no: '',
            rating: 0,
            ratingNote: '',
            showDetailDataPopUp: false,
            refIdTicket: '',
            refTicket: '',
            createdBy: '',
            createdAt: '',
            handledBy: '',
            status: '',
            changedAt: '-',

            fileUploadedInfoList: [],
            fileReadyToDownloadList: [],
            showDownloadPopUp: false,
            showUploadFilePopUp: false,
            currentTicketIdToUploadFile: null,

            isEvidence:false,
            downloadType: '',

            showRatingModal: false,
            saveRatingButtonIsDisable: false,
            ratingStar:[0],
            ratingStarOff: [0],

            commentTicket: {
                showPopUp: false,
                ticketCode: '',
                data: [],
                showMore: false,
                loader: false,
                postLoader: false,
                form: {
                    ticketId: null,
                    message: null,
                },
                temp: {
                    ticket_no: null,
                    ticket_id: null
                }
            },
            
            filter: {
                showFilter: false,
                title: '',
                selectedTicketCategory: null,
                priority: 1,
                withoutPriority: true,
                onlyForMe: false,
                statusObj: null,
                status: null,
                selectedAdmin: null,
                firstTimeLoad: false,
                selectedPIC: null,
                selectedSortBy: null,
                selectedTicketType: null
            },
            
            ticketTypeOptions: [
                {
                    label: 'Pengguna',
                    value: 1,
                },
                {
                    label: 'Sistem',
                    value: 2,
                }
            ],
            isAdmin: false,
            
            setStarOn: 0,
            setStarOff: 5,
            tempStarOn: 0,
            tempStarOff: 5
        }
    },
    computed: {
        ...mapState('auth', {
            loggedUserId: 'user_id',
            loggedUserPosition: 'position',
            fullname: 'fullname',
            profile_picture: 'profile_picture_thumb_base64',
            email: 'email'
        }),
        createdTicketTitle() {
            return this.returnText('Buat Tiket Laporan Sistem', 'Buat Tiket Laporan')
        },
        titleListReport() {
            return this.returnText('Daftar Tiket Laporan Sistem', 'Daftar Tiket Laporan')
        },
        CreateReportButtonText() {
            return this.returnText('Buat Laporan Tiket Sistem', 'Buat Laporan Tiket Pengguna')
        },
    },
    watch: {
        optionsTable: {
            deep: true,
            handler () {
                this.getTicketListData()
            }
        },
        fileUploadedIdList: {
            deep: true,
            handler: function (newValue, oldValue) {
                if(newValue.length >= this.fileAttachmentList.length) {
                    this.ticketAttachementIsValid = true
                    this.saveButtonText = 'Simpan'
                    this.saveLoader = false
                    this.validateButton()
                } else {
                    this.ticketAttachementIsValid = false
                }
            }
        }
    },
    mounted () {
        this.isAdmin = JSON.parse(localStorage.getItem('isAdmin'))
        this.getTicketListData()
        this.getCategoryTicketDataList()
    },
    methods: {
        returnText(textSystem, textUser) {
            if(this.isSystemTicketProp) {
                return textSystem
            } else {
                return textUser
            }
        },

        getCategoryTicketDataList(){
            var ticketType = 1
            if(this.isSystemTicketProp) {
                ticketType = 2
            }
            this.$store.dispatch({
                type:'ticket/getCategoryTicketList',
                ticketType: ticketType
            }).then(res => {
                this.ticketCategoryList = res.result
            }).catch(err=> {
                console.log(err)
                this.$toasted.error('Gagal mengambil data, silahkan coba kembali!').goAway(3000)
            })
        },
        getTicketListData() {
            this.loading = true
            const { sortBy, sortDesc, page, itemsPerPage } = this.optionsTable

            if(this.isAdmin && this.isSystemTicketProp) {
                this.filter.selectedTicketType = this.ticketTypeOptions[1]
            } else if(this.isAdmin && this.isSystemTicketProp == false) {
                this.filter.selectedTicketType = this.ticketTypeOptions[0]

            }
            
            if(this.isArchiveTicketProp) {
                this.filter.status = 'arsip'
            }

            this.$store.dispatch(
                {
                    type: 'ticket/getCustomTicketList',

                    pagination: {
                        limit: itemsPerPage,
                        offset: (page-1)*itemsPerPage
                    },
                    filter: this.filter
                }
            ).then(res=> {
                this.ticketList = res.result
                this.totalData = res.total_count
            }).catch( err => {
                console.log(err)
                this.$toasted.error('Gagal mengambil data, silahkan coba kembali!').goAway(3000)
            }).finally(() => {
                this.loading = false
            })
        },

        showAddPopUp() {
            this.showCreatePopUpModal = true
            this.ticketData.title = ''
            this.ticketData.description = ''
            this.ticketData.file_id = []
            this.ticketData.category_id = null
            this.saveButtonIsDisable = true
            this.saveLoader = false
            this.saveState = this.CREATE
            this.fileUploadedIdList= []
            this.fileUploadedInfoList = []
            this.ticketDescriptionIsValid = false
            this.ticketTitleIsValid = false
            this.ticketSelectedCategoryIsValid = false
            this.saveButtonText = "Simpan"

            this.placeHolderFileInput = 'Pilih (Gambar, Video, dan Lainnya)'
        },


        closePopupModal() {
            this.showDetailDataPopUp = false
            this.showCreatePopUpModal = false
            this.showDownloadPopUp = false
            this.showUploadFilePopUp = false
        },

        validateTitle() {
            if(this.ticketData.title.length > 0) {
                this.ticketTitleIsValid = true
            } else {
                this.ticketTitleIsValid = false
            }

            this.validateButton()
        },


        validateDescription() {
            if(this.ticketData.description.length > 0) {
                this.ticketDescriptionIsValid = true
            } else {
                this.ticketDescriptionIsValid = false
            }

            this.validateButton()
        },

        selectCategoryTicket(){
            if(this.selectedTicketCategory != null) {
                this.ticketSelectedCategoryIsValid = true
                this.ticketData.category_id = this.selectedTicketCategory.category_id
            } else {
                this.ticketSelectedCategoryIsValid = false
                this.ticketData.category_id = ''
            }
            this.validateButton()
        },

        validateButton() {
            if( this.ticketTitleIsValid &&
                this.ticketDescriptionIsValid &&
                // this.ticketSelectedCategoryIsValid &&
                this.ticketAttachementIsValid) {
                this.saveButtonIsDisable = false
            } else {
                this.saveButtonIsDisable = true
            }
        },

        validateAttachmentForm (event = null) {
            if (event && event.length > 0) {
                let fileCollection = []
                event.forEach((item) => {
                    fileCollection.push(item.name)
                })
                this.placeHolderFileInput = fileCollection.join(', ')
                this.fileAttachmentList = event
                this.uploadAttachmentProccess()
            } else {
                this.placeHolderFileInput = 'Pilih (Gambar, Video, dan Lainnya)'
            }
        },

        uploadAttachmentProccess() {
            this.saveButtonIsDisable = true
            this.saveButtonText = 'Mengunggah File...'
            this.saveLoader = true

            this.fileAttachmentList.forEach(currentFileData=> {
                let fileType = this.getFileTypeForUpload(currentFileData.type)
                this.uploadFile(currentFileData, fileType)
            })
        },

        async uploadFile(currentFileData, fileType) {
            this.$store.dispatch({
                    type: 'ticket/uploadAttachment',
                    fileData: currentFileData,
                    typeData: fileType
            }).then(res=> {
                    this.fileUploadedIdList.push(res.result.file_id)
                    let fileData = {
                        file_id: res.result.file_id,
                        file_type: fileType,
                        name: currentFileData.name,
                        isChoosed: true
                    }
                    this.fileUploadedInfoList.push(fileData)
                }
            ).error(
                err => {
                    console.log(err)
                    this.$toasted.error('Gagal mengunggah data, silahkan coba kembali!').goAway(3000)
                }
            )
        },

        getFileTypeForUpload(typeFile) {
            if(typeFile.includes('image')) {
                return 'image'
            }
            if(typeFile.includes('video')) {
                return 'video'
            }
            return 'others'
        },

        saveData() {
            this.createTicketData()
        },

        createTicketData() {
            this.saveLoader = true
            this.saveButtonIsDisable = true

            var listFileId = []

            if(this.fileUploadedInfoList.length > 0) {
                this.fileUploadedInfoList.forEach(fileInfoData => {
                    if(fileInfoData.isChoosed) {
                        listFileId.push(fileInfoData.file_id)
                    }
                })
            }

            if(listFileId.length > 0 ) {
                this.ticketData.file_id = listFileId
            }

            this.$store.dispatch(
                {
                    type: 'ticket/createTicket',
                    ticketData: this.ticketData
                }
            ).then(res=> {
                this.$toasted.success('Terima kasih telah melaporkan kendala anda mengenai aplikasi kepada kami, <br> kami akan segera memproses pelaporan anda.').goAway(5000)
                this.getTicketListData()
                this.closePopupModal()
            }).catch( err => {
                console.log(err)
                this.$toasted.error('Gagal menambah data, silahkan coba kembali!').goAway(3000)
            }).finally(() => {
                this.saveLoader = false
                this.saveButtonIsDisable = false
            })
        },

        downloadAttachment(item, isEvidenceProp) {
            this.showDownloadPopUp = true
            // this.fileReadyToDownloadList = item.file_attachment
            this.isEvidence = isEvidenceProp
            if (isEvidenceProp) {
                this.downloadType= 'Bukti Penyelesaian'
                this.fileReadyToDownloadList = item.evidence_file
            } else {
                this.downloadType= 'File Pendukung'
                this.fileReadyToDownloadList = item.file_attachment
            }

        },

        async downloadFile(currentFileData) {

            this.$store.dispatch({
                type: 'ticket/downloadAttachment',
                fileData: currentFileData
            }).then( res => {
                if(res) {
                    let physicalFile = new File(
                        [res.file],
                        'test',
                        {
                            type: res.file.type,
                            lastModified: Date.now()
                        }
                    )

                    let fileURL = window.URL.createObjectURL(physicalFile)
                    let link = document.createElement("a")
                        link.href = fileURL
                        link.download = res.filename
                        link.click()

                    setTimeout(function() {
                        window.URL.revokeObjectURL(fileURL)
                    }, 100)

                } else {
                    this.$toasted.info('Berkas tidak ditemukan!').goAway(3000)
                }
            }).catch( err => {
                console.log(err)
                this.$toasted.error('Gagal memuat berkas, silahkan coba kembali!').goAway(3000)
            })
        },

        showDetail(item) {
            this.showDetailDataPopUp = true
            this.description = item.description
            this.ticket_no = item.ticket_no
            this.rating = item.rating
            this.createdBy= item.report_user.username
            this.createdAt= item.created_at
            this.handledBy= item.pic
            this.status= item.status
            this.ratingNote = (item.catatan_rating == null)? 'Tidak ada catatan' : item.catatan_rating 
            if (item.changed_at && item.changed_at != '') {
                this.changedAt = item.changed_at
            }

            var x = document.getElementById("refTicketId")
            x.style.display = "none"
            if (item.refTicket) {
                this.ref_ticket = "T-" + (100000 + item.refTicket)
                x.style.display = "none"
            } else {
                x.style.display = "none"
            }

            this.ratingStar = []
            this.ratingStarOff = []
            for (var i = 0; i < 5; i++ ){
                if (i < this.rating) {
                    this.ratingStar.push(0)
                } else {
                    this.ratingStarOff.push(0)
                }
            }
        },

        unChoosedItem(item) {
            this.$confirm({
                auth: false,
                message: 'Apakah yakin ingin menghapus file yang dipilih?',
                button: {
                    no: 'Tidak',
                    yes: 'Ya'
                },
                callback: confirm => {
                    if (confirm) {
                        this.fileUploadedInfoList.forEach(currentFile=> {
                            if(currentFile.file_id == item.file_id) {
                                currentFile.isChoosed = false
                            }
                        })
                    }
                }
            })
        },

        showUploadExtraFilePopUp(item) {
            this.showUploadFilePopUp = true
            this.fileUploadedIdList= []
            this.fileUploadedInfoList = []
            this.placeHolderFileInput = 'Pilih (Gambar, Video, dan Lainnya)'
            this.currentTicketIdToUploadFile = item.ticket_id
        },

        saveUploadFileData() {
            this.saveLoader = true
            this.saveButtonIsDisable = true

            var listFileId = []

            if(this.fileUploadedInfoList.length > 0) {
                this.fileUploadedInfoList.forEach(fileInfoData => {
                    if(fileInfoData.isChoosed) {
                        listFileId.push(fileInfoData.file_id)
                    }
                })
            }

            this.$store.dispatch(
                {
                    type: 'ticket/addFileForExistingTicket',
                    ticketData: {
                        ticket_id: this.currentTicketIdToUploadFile,
                        file_id: listFileId
                    }
                }
            ).then(res=> {
                this.$toasted.success('Sukses Menambah Data').goAway(3000)
                this.getTicketListData()
                this.closePopupModal()
            }).catch( err => {
                console.log(err)
                this.$toasted.error('Gagal menambah data, silahkan coba kembali!').goAway(3000)
            }).finally(() => {
                this.saveLoader = false
                this.saveButtonIsDisable = false
            })
        },

        showRatingPopUp(item) {
            this.title = item.title
            this.showRatingModal = true
            this.saveRatingButtonIsDisable = true
            this.ticketData.ticket_id = item.ticket_id
        },

        closeRatingPopUp() {
            this.showRatingModal = false
        },

        validateRating() {
            if (0 < this.ticketData.rating &&
                this.ticketData.rating < 11) {
                    this.saveRatingButtonIsDisable = false
            } else {
                this.saveRatingButtonIsDisable = true
            }
        },

        saveRatingData() {
            this.$store.dispatch(
                {
                    type: 'ticket/uploadSurveyTicket',
                    ticketData: this.ticketData,
                }
            ).then(res=> {
                this.$toasted.success('Sukses Memasukan Data').goAway(3000)
                this.getTicketListData()
                this.closeRatingPopUp()
            }).catch( err => {
                console.log(err)
                this.$toasted.error('Gagal memasukan data, silahkan coba kembali!').goAway(3000)
            }).finally(() => {
                this.saveLoader = false
                this.saveButtonIsDisable = false
            })
        },

        showTicketCommentPopUp(item) {
            this.commentTicket.showPopUp= true
            this.commentTicket.ticketCode= item.ticket_no
            // get ticket comment

            this.commentTicket.data = []
            this.commentTicket.form.ticketId = item.ticket_id

            this.commentTicket.temp.ticket_no = item.ticket_no
            this.commentTicket.temp.ticket_id = item.ticket_id

            this.getTicketComment(item.ticket_id)

        },

        getTicketComment(param){
            this.$store.dispatch(
                {
                    type: 'ticket/getTicketChatList',
                    pagination: {
                        limit: 5,
                        offset: 0
                    },
                    filter: {
                        ticketId: param
                    }
                }
            ).then( res => {
                let items = res.result
                if (items.length > 0) {
                    items.forEach((item) => {
                        this.commentTicket.data.push(item)
                    })
                } else {
                    this.commentTicket.showMore = false
                }
            }).finally( () => {
                this.commentTicket.loader = false
            })
        },

        postComment(){
            this.commentTicket.ticketCode = this.commentTicket.form.message
            if (this.commentTicket.form.message == '')
				return false

			this.commentTicket.postLoader = true
			this.commentTicket.ticketCode = 'ok'
			this.$store.dispatch({
				type: 'ticket/postTicketComment',
				formInput: this.commentTicket.form
			}).then( res => {
				console.log("Result: ",res)
			}).catch( err => {
				console.log(err)
				this.$toasted.error('Gagal menambah komentar, silahkan coba kembali!').goAway(3000)
			}).finally( () => {
				setTimeout(() => {
                    this.commentTicket.postLoader = false
                    this.commentTicket.form.message = ''
                    this.closeCommentTicketPopUp(true)
				}, 500)
			})

        },

        closeCommentTicketPopUp(restart) {
            this.commentTicket.showPopUp= false
            if (restart) {
                this.showTicketCommentPopUp(this.commentTicket.temp)
            }
        },

        hoverStar(rate) {
            this.setStarOn = rate
            this.setStarOff = 5-rate
        },
        
        leaveHoverStar() {
            this.setStarOn = this.tempStarOn
            this.setStarOff = this.tempStarOff
        },

        setStar(rate) {
            this.setStarOn = rate
            this.setStarOff = 5-rate
            this.tempStarOn = this.setStarOn
            this.tempStarOff = this.setStarOff
            this.ticketData.rating = rate
        }
    }
}

</script>

<style scoped>

</style>