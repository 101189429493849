<template>
    <div class="doc-container">
        <CTabs :active-tab.sync="activeTab">
            <CTab>
                <template slot="title">Tiket Pengguna</template>
                <div class="mt-20">
                    <ticket :is-system-ticket-prop="false" :is-archive-ticket-prop="false"/>
                </div>
            </CTab>            
            <CTab>
                <template slot="title">Closed Tiket Pengguna</template>
                <div class="mt-20">
                    <ticket :is-system-ticket-prop="false" :is-archive-ticket-prop="true"/>
                </div>
            </CTab>
            <CTab v-if="isAdmin">
                <template slot="title">Tiket Sistem</template>
                <div class="mt-20">
                    <ticket :is-system-ticket-prop="true" :is-archive-ticket-prop="false"/>
                </div>
            </CTab>
            <CTab v-if="isAdmin"> 
                <template slot="title">Closed Tiket Sistem</template>
                <div class="mt-20">
                    <ticket :is-system-ticket-prop="true" :is-archive-ticket-prop="true"/>
                </div>
            </CTab>
        </CTabs>
    </div>
</template>

<script>
import Ticket from '@/views/ticket/Ticket.vue'
import { mapState } from 'vuex'
export default {
    components: {
        'ticket': Ticket,
    },
    data() {
        return {
            activeTab: 0,
            isAdmin: false
        }
    },
    computed: {
        ...mapState('auth', {
            loggedUserId: 'user_id',
            loggedUserPosition: 'position',
            groups: 'groups',
            fullname: 'fullname',
            profile_picture: 'profile_picture_thumb_base64',
            email: 'email'
        }),

    },  
    mounted () {
        this.isAdmin  = JSON.parse(localStorage.getItem('isAdmin'))
    }
}
</script>